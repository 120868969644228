<template>
  <FimLink v-if="to" v-bind="{ ...$props, ...$attrs, to, target }">
    <template v-if="$slots.icon" #icon>
      <slot name="icon" />
    </template>
    <template v-if="$slots.iconAfter" #iconAfter>
      <slot name="iconAfter" />
    </template>
    <slot>{{ blok.cta_label }}</slot>
  </FimLink>
</template>

<script setup lang="ts">
import type { SbLink } from '~/storyblok/types/storyblok'
import { getLinkTarget, resolvSbLink } from '../utils/links'
const { $currentShop } = useNuxtApp()

const props = defineProps({
  blok: {
    type: Object as PropType<SbLink>,
    required: true,
  },
})

const to = computed(() => resolvSbLink(props.blok, $currentShop))
const target = getLinkTarget(props.blok)
</script>
